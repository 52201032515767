/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react"
import { Flex, Box, Heading, Badge } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { compose } from "recompose"
import ClientList from "../../components/molecules/ClientList"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"

const Clients = ({ firebase }) => {
  const [provider, setProvider] = useState(null)
  const [clients, setClients] = useState([])
  const [currentClient, setCurrentClient] = useState(null)
  const [loading, setLoading] = useState(false)
  const onClientsLoad = (data) => {
    setClients(data)
  }

  const onSelectProvider = async (currentProvider) => {
    setProvider(currentProvider)
    if (currentProvider) {
      try {
        setLoading(true)
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <Box w="100%" h="100%" p={3}>
      <Heading>CLIENTES</Heading>
      <Flex w="100%">
        <Box w="25%" h="calc(100vh - 60px)" overflow="hidden">
          <ClientList
            handleClientSelect={setCurrentClient}
            handleClientsLoad={onClientsLoad}
            externalLoading={loading}
            selectedClient={currentClient}
          />
        </Box>
        <Box w="75%" h="calc(100vh - 100px)" m="8" overflowY="scroll">
          <Flex w="100%" h="100%" justify="center" flexWrap="wrap">
            {clients.map((c) => (
              <Box
                m={4}
                p={2}
                minWidth="300px"
                maxWidth="500px"
                borderWidth={1}
                borderRadius={8}
                boxShadow="sm"
                key={c.objectID}
                cursor="pointer"
                flex="1"
                onClick={() => setCurrentClient(c)}
              >
                <Box d="flex" alignItems="baseline">
                  <Badge borderRadius="full" px="2" colorScheme="teal">
                    {c.legalId}
                  </Badge>
                </Box>
                <Box
                  mt="1"
                  fontWeight="semibold"
                  as="h4"
                  lineHeight="tight"
                  isTruncated
                  textAlign="left"
                >
                  Nombre: {c.displayName}
                </Box>
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Email: {c.email}
                </Box>
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Teléfono 1: {c.phoneNumber}
                </Box>
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Teléfono 2: {c.mobileNumber}
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Flex>
    </Box>
  )
}

Clients.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => !!authUser
const ComposedClients = compose(
  withFirebase,
  withAuthorization(condition)
)(Clients)
export default ComposedClients
