import React from "react"

// eslint-disable-next-line import/prefer-default-export
const Home = () => (
  <div>
    <span>Home</span>
  </div>
)

export default Home
