/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react"
// import get from "lodash.get"
// import truncate from "lodash.truncate"
import dayjs from "dayjs"
// import utc from "dayjs/plugin/utc"
// import get from "lodash.get"
import { Flex, Box, Heading } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { compose } from "recompose"
import Calendar from "../../components/molecules/Calendar"
import ProvidersList from "../../components/molecules/ProvidersList"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization } from "../../context/Session"
// import * as ROLES from "../../utils/Constants/Roles"

// dayjs.extend(utc)
const Dashboard = ({ firebase }) => {
  const [provider, setProvider] = useState(null)
  const [types, setTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [events, setEvents] = useState([])
  // const authUser = useContext(AuthUserContext)
  // const isOdonto = get(authUser, ["roles", "PROVIDER"])

  useEffect(() => {
    const getTypes = async () => {
      const snapshot = await firebase.getTypes()
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => (a.description > b.description ? 1 : -1))
      setTypes(data)
    }
    if (firebase) {
      getTypes()
    }
  }, [firebase])

  const onSelectProvider = async (currentProvider) => {
    setProvider(currentProvider)
    if (currentProvider) {
      try {
        setLoading(true)
        const snapshot = await firebase.getAppointments(currentProvider.id)
        if (snapshot.docs.length > 0) {
          const data = snapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .sort((a, b) => (a.timestampDate > b.timestampDate ? 1 : -1))
            .map((evt) => ({
              id: evt.id,
              title: `${evt?.order > 1 ? `${evt?.order} - ` : ""} ${
                evt.client
              } - ${evt.reason} - ${evt.clientPhoneNumber}`,
              start: dayjs(evt.timestampDate)
                // .utc()
                .format("YYYY-MM-DDTHH:mm:ssZ"),
              end: dayjs(evt.timestampDate)
                .add(evt.duration, "m")
                // .utc()
                .format("YYYY-MM-DDTHH:mm:ssZ"),
              backgroundColor: evt?.status?.includes("A")
                ? "green"
                : evt?.status?.includes("C")
                ? "red"
                : "#339FFF",
              canceled: !!evt?.status?.includes("C"),
            }))
          setEvents(data)
        } else {
          setEvents([])
        }
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onDateChange = () => {
    console.log("date change")
  }

  return (
    <Box w="100%" h="100%" p={3} overflowY="scroll">
      <Heading>CITAS</Heading>
      <Flex w="100%">
        <Box
          w="25%"
          h="calc(100vh - 60px)"
          overflowY="scroll"
          overflowX="hidden"
        >
          <ProvidersList
            handleSelectProvider={onSelectProvider}
            externalLoading={loading}
          />
        </Box>
        <Box w="75%" h="100%">
          <Calendar
            provider={provider}
            handleDateChange={onDateChange}
            events={events}
            types={types}
          />
        </Box>
      </Flex>
    </Box>
  )
}

Dashboard.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) => authUser
const ComposedDashboard = compose(
  withFirebase,
  withAuthorization(condition)
)(Dashboard)
export default ComposedDashboard
