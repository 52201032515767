/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react"
import { Flex, Box, Spinner, Heading, Text } from "@chakra-ui/react"
import get from "lodash.get"
import PropTypes from "prop-types"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"

const ProvidersList = ({ firebase, handleSelectProvider, externalLoading }) => {
  const authUser = useContext(AuthUserContext)
  const [providers, setProviders] = useState([])
  const [currentProvider, setCurrentProvider] = useState(null)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const getProviders = async () => {
      const snapshot = await firebase.getProviders()
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => (a.name > b.name ? 1 : -1))
      if (get(authUser, ["roles", "PROVIDER"])) {
        const filteredData = data.filter((d) => d.id === authUser.providerId)
        setProviders(filteredData)
      } else {
        setProviders(data)
      }
    }
    getProviders()
  }, [firebase, authUser])

  useEffect(() => {
    if (providers.length > 0) {
      setCurrentProvider(providers[0].id)
      handleSelectProvider(providers[0])
    }
    setLoading(false)
  }, [providers])

  // useEffect(() => {
  //   handleSelectProvider(currentProvider)
  // }, [currentProvider, handleSelectProvider])

  const handleProviderChange = (prov) => {
    setCurrentProvider(prov.id)
    handleSelectProvider(prov)
  }

  if (loading || externalLoading)
    return (
      <Flex height="100%" justify="center" align="center" overflow="hidden">
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Flex>
    )
  return (
    <Flex direction="column" justify="center">
      <Heading fontSize="md" mb="2">
        MÉDICOS DISPONIBLES
      </Heading>
      {providers.map((p) => (
        <Box
          color={currentProvider === p.id ? "#1c3d8e" : "gray.800"}
          fontSize={currentProvider === p.id ? "xl" : "sm"}
          key={p.id}
          py="2"
          cursor="pointer"
          _hover={{
            transform: "scale(1.05) translateX(13px)",
            fontWeight: 800,
          }}
          onClick={() => handleProviderChange(p)}
        >
          <Text width="full" align="left">
            {p.name}
          </Text>
        </Box>
      ))}
    </Flex>
  )
}

ProvidersList.propTypes = {
  externalLoading: PropTypes.bool.isRequired,
  firebase: PropTypes.shape().isRequired,
  handleSelectProvider: PropTypes.func.isRequired,
}

export default withFirebase(ProvidersList)
