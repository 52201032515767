/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useContext, useRef } from "react"
import dayjs from "dayjs"
import * as Yup from "yup"
import utc from "dayjs/plugin/utc"
import {
  Box,
  Badge,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Text,
  VStack,
  StackDivider,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  FormControl,
  FormLabel,
  FormErrorMessage,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogCloseButton,
  AlertDialogFooter,
  AlertDialogBody,
  AlertDialogHeader,
  useDisclosure,
  useTheme,
  useToast,
} from "@chakra-ui/react"
import { MdPerson, MdAddBox } from "react-icons/md"
import { Formik, Form, Field } from "formik"
import { SearchIcon } from "@chakra-ui/icons"
import styled from "@emotion/styled"
import isPropValid from "@emotion/is-prop-valid"
import PropTypes from "prop-types"
import { compose } from "recompose"
import MarketingTargets from "../../components/organisms/MarketingTargets"
import { withFirebase } from "../../utils/Firebase"
import { withAuthorization, AuthUserContext } from "../../context/Session"
import { GET_CONTACTS_URL } from "../../utils/Constants/Functions"
import * as ROLES from "../../utils/Constants/Roles"

dayjs.extend(utc)

const BoxContainer = styled(Box, {
  shouldForwardProp: (prop) => isPropValid(prop) && prop !== "isActive",
})`
  border-color: ${(props) =>
    props.isActive
      ? props.theme.colors.teal["500"]
      : props.theme.colors.gray["300"]};
  &:hover {
    > p {
      font-weight: 800;
    }
  }
  > p {
    font-weight: ${(props) => (props.isActive ? "800" : "400")};
  }
`

const Marketing = ({ firebase }) => {
  const theme = useTheme()
  const [contactList, setContactList] = useState([])
  const [value, setValue] = useState("")
  const [activeCard, setActiveCard] = useState(null)
  const [loading, setLoading] = useState(false)
  const [actualList, setActualList] = useState([])
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenAlert,
    onOpen: onOpenAlert,
    onClose: onCloseAlert,
  } = useDisclosure()
  const cancelRef = useRef()
  const authUser = useContext(AuthUserContext)
  const toast = useToast()
  useEffect(() => {
    const getContactList = async () => {
      // const token = await firebase.getIdToken()
      // const response = await fetch(GET_CONTACTS_URL, {
      //   method: "GET",
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //     "Content-Type": "application/json",
      //   },
      // })
      const responseData = await firebase.getMarketingLists()
      setContactList(
        responseData.docs
          .map((d) => ({ id: d.id, ...d.data() }))
          .sort((a, b) => (dayjs(a.CreatedAt) > dayjs(b.CreatedAt) ? 1 : -1))
      )
      setLoading(false)
      return true
    }
    if (!authUser.loading) {
      setLoading(true)
      getContactList()
    }
  }, [firebase, authUser])

  useEffect(() => {
    const getContacts = async () => {
      const cls = await firebase.getContactsMarketingList(activeCard)
      const newCls = cls.docs.map((doc) => doc.data())
      setActualList(newCls)
    }
    if (activeCard) {
      getContacts()
    }
  }, [firebase, activeCard])

  function handleChange({ target }) {
    setValue(target.value)
  }

  return (
    <Box w="100%" h="100%" p={3} overflowY="scroll">
      <Heading>MARKETING</Heading>
      <Flex w="100%" h="calc(100vh - 95px)">
        <Box w="25%" h="calc(100vh - 70px)" overflow="hidden">
          <Flex direction="column" justify="flex-start" height="100%">
            <Heading fontSize="md" mb="2">
              Filtrar Listas
            </Heading>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Buscar..."
                value={value}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={handleChange}
              />
            </InputGroup>
            <Button
              mt="10"
              leftIcon={<MdAddBox />}
              colorScheme="teal"
              variant="outline"
              onClick={onOpen}
            >
              Crear Nueva Lista
            </Button>
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              spacing={1}
              align="stretch"
              width="100%"
              height="calc(100vh - 250px)"
              overflowY="auto"
              mt="4"
            >
              {contactList.map((c) => (
                <BoxContainer
                  theme={theme}
                  isActive={activeCard === c.id}
                  width="100%"
                  height="130px"
                  borderRadius="md"
                  shadow="md"
                  borderWidth="1px"
                  key={c.id}
                  my={1}
                  p={2}
                  cursor="pointer"
                  onClick={() => setActiveCard(c.id)}
                >
                  <Text align="left">{`Nombre: ${c.name}`}</Text>
                  <Text align="left">{`Creada: ${dayjs(c.createdAt).format(
                    "DD/MM/YYYY"
                  )}`}</Text>
                  <Text align="left">{`Contactos: # ${c.totalContacts}`}</Text>
                  <Button
                    mt="2"
                    variant="outline"
                    colorScheme="red"
                    size="xs"
                    onClick={() => {
                      setActiveCard(c.id)
                      onOpenAlert()
                    }}
                  >
                    Eliminar
                  </Button>
                </BoxContainer>
              ))}
            </VStack>
          </Flex>
        </Box>
        <Box w="75%" h="100%" m="8">
          <Tabs variant="enclosed">
            <TabList>
              <Tab>Ingresados</Tab>
              <Tab>Agregar</Tab>
              <Tab isDisabled color="gray.300">
                Whatsapp
              </Tab>
              <Tab isDisabled color="gray.300">
                Correo
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Flex w="100%" h="100%" justify="center" flexWrap="wrap">
                  {actualList.map((c) => (
                    <Box
                      m={4}
                      p={2}
                      minWidth="300px"
                      maxWidth="500px"
                      borderWidth={1}
                      borderRadius={8}
                      boxShadow="sm"
                      key={c.ID}
                      cursor="pointer"
                      flex="1"
                    >
                      <Box d="flex" alignItems="baseline">
                        <Badge borderRadius="full" px="2" colorScheme="teal">
                          {c.id}
                        </Badge>
                      </Box>
                      <Box
                        mt="1"
                        fontWeight="semibold"
                        as="h4"
                        lineHeight="tight"
                        isTruncated
                        textAlign="left"
                      >
                        Nombre: {c.name}
                      </Box>
                      <Box
                        textAlign="left"
                        as="p"
                        color="gray.600"
                        fontSize="sm"
                      >
                        Email: {c.email}
                      </Box>
                      <Box
                        textAlign="left"
                        as="p"
                        color="gray.600"
                        fontSize="sm"
                      >
                        Teléfono 1: {c.phoneNumber}
                      </Box>
                      <Box
                        textAlign="left"
                        as="p"
                        color="gray.600"
                        fontSize="sm"
                      >
                        Teléfono 2: {c.mobileNumber}
                      </Box>
                    </Box>
                  ))}
                </Flex>
              </TabPanel>
              <TabPanel>
                <MarketingTargets
                  listId={activeCard}
                  actualList={actualList || []}
                />
              </TabPanel>
              <TabPanel>Whatspp</TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
      <Drawer isOpen={isOpen} placement="left" onClick={onClose} size="sm">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Agregar Nueva Lista</DrawerHeader>
            <DrawerBody>
              <Formik
                initialValues={{
                  active: true,
                  createdAt: dayjs().valueOf(),
                  description: undefined,
                  name: undefined,
                  totalContacts: 0,
                  type: "",
                }}
                validationSchema={Yup.object({
                  name: Yup.string().required("Nombre es requerido"),
                  description: Yup.string().required(
                    "Una descripción es requerida"
                  ),
                })}
                onSubmit={async (values, actions) => {
                  try {
                    const newList = await firebase.addMarketingLists(values)
                    setContactList((prevValues) => [...prevValues, newList])
                    toast({
                      title: "Lista agregada.",
                      description: "Se ha agregado la lista exitosamente",
                      status: "success",
                      duration: 3000,
                      isClosable: true,
                    })
                  } catch (error) {
                    toast({
                      title: "Error.",
                      description: "Ha ocurrido un error agregando la lista.",
                      status: "error",
                      duration: 3000,
                      isClosable: true,
                    })
                  } finally {
                    onClose()
                  }
                }}
              >
                {({ isSubmitting, dirty, isValid, setFieldValue }) => (
                  <Form>
                    <Field name="name">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.name && form.touched.name}
                        >
                          <FormLabel htmlFor="name">* Nombre:</FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPerson color="gray.300" />
                            </InputLeftElement>
                            <Input {...field} id="name" placeholder="Nombre" />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.name}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field name="description">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.description && form.touched.description
                          }
                        >
                          <FormLabel htmlFor="description">
                            * Descripcion:
                          </FormLabel>
                          <InputGroup>
                            <InputLeftElement
                              pointerEvents="none"
                              fontSize="1.2em"
                              p="0"
                              zIndex="1"
                            >
                              <MdPerson color="gray.300" />
                            </InputLeftElement>
                            <Input
                              {...field}
                              id="description"
                              placeholder="Descripción"
                            />
                          </InputGroup>
                          <FormErrorMessage>
                            {form.errors.description}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Button
                      mt={4}
                      colorScheme="teal"
                      isLoading={isSubmitting}
                      type="submit"
                      disabled={!dirty || isSubmitting || !isValid}
                    >
                      Agregar
                    </Button>
                  </Form>
                )}
              </Formik>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancelar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={onCloseAlert}
        isOpen={isOpenAlert}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Eliminar lista?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            Esta seguro que desea eliminar esta lista?
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onCloseAlert}>
              Cancelar
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={async () => {
                try {
                  await firebase.deleteMarketingList(activeCard)
                  setContactList(contactList.filter((c) => c.id !== activeCard))
                  toast({
                    title: "Lista eliminada.",
                    description: "Se ha eliminado la lista exitosamente",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                  })
                  setActiveCard(null)
                  onCloseAlert()
                } catch (error) {
                  toast({
                    title: "Error.",
                    description: "Ha ocurrido un error eliminando la lista.",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  })
                }
              }}
            >
              Aceptar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </Box>
  )
}

Marketing.propTypes = {
  firebase: PropTypes.shape().isRequired,
}

const condition = (authUser) =>
  !!authUser &&
  (authUser.roles[ROLES.SUPER_ADMIN] || authUser.roles[ROLES.MARKETING])
const ComposedMarketing = compose(
  withFirebase,
  withAuthorization(condition)
)(Marketing)
export default ComposedMarketing
