import React from "react"
import { ChakraProvider, Box } from "@chakra-ui/react"
import { ToastProvider } from "react-toast-notifications"
import Routes from "./components/molecules/Routes"
import { withAuthentication } from "./context/Session"
import "./static/fonts/fonts.css"
import theme from "./theme"
import "./App.css"

const App = () => (
  <ChakraProvider resetCSS theme={theme}>
    <>
      <Box w="100%" overflow="hidden" className="App">
        <ToastProvider autoDismiss>
          <Routes />
        </ToastProvider>
      </Box>
    </>
  </ChakraProvider>
)

const RootApp = withAuthentication(App)
export default RootApp
