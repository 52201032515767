import React from "react"
import { Flex } from "@chakra-ui/react"
import LoginForm from "../../components/organisms/LoginForm"

// eslint-disable-next-line import/prefer-default-export
const Login = () => (
  <Flex w="100vw" h="100vh" p={3} className="login">
    <LoginForm />
  </Flex>
)

export default Login
