import React, { useContext } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import get from "lodash.get"
import Home from "../../../pages/Home"
import Dashboard from "../../../pages/Dashboard"
import Clients from "../../../pages/Clients"
import Marketing from "../../../pages/Marketing"
import Login from "../../../pages/Login"
import NotFound from "../../../pages/NotFound"
import Layout from "../../organisms/Layout"
import * as ROUTES from "../../../utils/Constants/Routes"
import { AuthUserContext } from "../../../context/Session"

function Routes() {
  const authUser = useContext(AuthUserContext)
  return (
    <Router>
      <Route
        render={(routeProps) => (
          <Layout {...routeProps}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => {
                  if (get(authUser, ["roles", "MARKETING"]))
                    return <Redirect to={ROUTES.MARKETING} />
                  return <Redirect to={ROUTES.DASHBOARD} />
                }}
              />
              <Route exact path={ROUTES.HOME}>
                <Home />
              </Route>
              <Route exact path={ROUTES.DASHBOARD}>
                <Dashboard />
              </Route>
              <Route exact path={ROUTES.CLIENTS}>
                <Clients />
              </Route>
              <Route exact path={ROUTES.MARKETING}>
                <Marketing />
              </Route>
              <Route exact path={ROUTES.LOGIN}>
                <Login />
              </Route>
              <Route component={NotFound} />
              {/* <PrivateRoute exact path={ROUTES.ABOUT} component={About} /> */}
              {/* <PrivateRoute exact path={ROUTES.DASHBOARD} component={Dashboard} /> */}
            </Switch>
          </Layout>
        )}
      />
    </Router>
  )
}

export default Routes
