/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/no-onchange */
import React, { useState, useEffect, createRef, useContext } from "react"
import * as Yup from "yup"
import { Formik, Form, Field } from "formik"
import dayjs from "dayjs"
import get from "lodash.get"
import truncate from "lodash.truncate"
import PropTypes from "prop-types"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import interactionPlugin from "@fullcalendar/interaction"
import DatePicker, { getYear } from "react-datepicker"
import Select from "react-select"
import {
  MdPhone,
  MdMail,
  MdPhoneIphone,
  MdLocationOn,
  MdPerson,
  MdPersonOutline,
  MdRecentActors,
} from "react-icons/md"
import AsyncSelect from "react-select/async"
import {
  Input,
  InputGroup,
  InputLeftElement,
  Button,
  Box,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useDisclosure,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Text,
  RadioGroup,
  Stack,
  Radio,
  Spacer,
} from "@chakra-ui/react"
import algoliasearch from "algoliasearch"
import { useClipboard } from "use-clipboard-copy"
import { withFirebase } from "../../../utils/Firebase"
import { AuthUserContext } from "../../../context/Session"
import { MAIL_URL } from "../../../utils/Constants/Functions"

// dayjs.extend(utc)
// dayjs().utcOffset(6)
const searchClient = algoliasearch(
  "78798OTGJ4",
  "1c09830d40d200346f2bf53035e3f7e9"
)
const index = searchClient.initIndex("active_clients")
const years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) =>
  (i + 1950).toString()
)

const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
]

const Calendar = ({ firebase, provider, handleDateChange, events, types }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure()
  const clipboard = useClipboard()
  const toast = useToast()
  const authUser = useContext(AuthUserContext)
  const [info, setInfo] = useState(null)
  const [type, setType] = useState(null)
  const [client, setClient] = useState(null)
  const [clients, setClients] = useState([])
  const [creating, setCreating] = useState(null)
  const [eventInfo, setEventInfo] = useState(null)
  const [localEvents, setLocalEvents] = useState(events)
  const [status, setStatus] = useState(true)
  const [tabIndex, setTabIndex] = React.useState(0)
  const [showCanceled, setShowCanceled] = useState("1")
  const [duration, setDuration] = useState({
    label: "15 min",
    value: 15,
  })
  const [notes, setNotes] = useState("")
  const calendarRef = createRef()
  // const isOdonto = get(authUser, ["roles", "PROVIDER"])

  const durationOptions = [
    {
      label: "15 min",
      value: 15,
    },
    {
      label: "30 min",
      value: 30,
    },
    {
      label: "45 min",
      value: 45,
    },
    {
      label: "1 hora",
      value: 60,
    },
  ]

  const statusOptions = [
    {
      label: "Creada",
      value: "I",
    },
    {
      label: "Confirmada",
      value: "A",
    },
    {
      label: "Cancelada",
      value: "C",
    },
  ]

  const handleDrawerClose = () => {
    const calendarApi = info.view.calendar
    calendarApi.unselect() // clear date selection
    onClose()
  }

  const handleEventUpdate = async () => {
    const calendar = calendarRef.current.getApi()
    const currentEvent = calendar.getEventById(eventInfo.evtId)
    try {
      const first = await firebase.updateAppointment(
        eventInfo,
        provider.id,
        eventInfo.evtId
      )

      const second = await firebase.updateLiveAppointment(
        eventInfo,
        provider.id,
        eventInfo.evtId
      )
      // eslint-disable-next-line no-nested-ternary
      const color = eventInfo.status.includes("A")
        ? "green"
        : eventInfo.status.includes("C")
        ? "red"
        : "#339FFF"

      currentEvent.setProp(
        "title",
        `${eventInfo.client} - ${eventInfo.reason} - ${eventInfo.clientPhoneNumber}`
      )
      currentEvent.setProp("backgroundColor", color)
      currentEvent.setEnd(
        dayjs(eventInfo.timestampDate)
          .add(eventInfo.duration, "m")
          .format("YYYY-MM-DDTHH:mm:ssZ")
      )
      toast({
        title: "Cita actualizada.",
        description: "Se ha actualizado la cita seleccionada",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      onCloseEdit()
      setEventInfo(null)
    } catch (error) {
      toast({
        title: "Error.",
        description: "Ha ocurrido un error actualizando la cita.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleDrawerSave = async () => {
    setCreating(true)
    try {
      const calendarApi = info.view.calendar
      const aptClient = clients.find((c) => c.objectID === client.value)
      const newAppointment = {
        client: aptClient.displayName,
        clientEmail: aptClient.email,
        clientLegalId: aptClient.legalId,
        clientMobileNumber: aptClient.mobileNumber,
        clientPhoneNumber: aptClient.phoneNumber,
        clientGender: get(aptClient, "gender", ""),
        comments: notes,
        duration: duration.value,
        reason: type.label,
        reasonId: type.value,
        providerName: provider.name,
        timestampDate: dayjs(info.startStr).valueOf(),
        status: "I",
      }
      const created = await firebase.addAppointment(newAppointment, provider.id)
      calendarApi.unselect() // clear date selection
      // calendarApi.addEvent({
      //   id: created.id,
      //   title: `${aptClient.displayName} - ${newAppointment.reason} - ${aptClient.phoneNumber}`,
      //   // title: `${aptClient.client} - ${aptClient.reason} - ${aptClient.clientPhoneNumber}`,
      //   start: info.start,
      //   end: dayjs(created.timestampDate)
      //     .add(created.duration, "m")
      //     // .utc()
      //     .format("YYYY-MM-DDTHH:mm:ssZ"),
      //   backgroundColor: "#339FFF",
      //   extendedProps: {
      //     isNewEvent: true,
      //   },
      // })
      setCreating(false)
      toast({
        title: "Cita agendada.",
        description: `Se ha agendado la cita para: ${aptClient.displayName}`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
      onClose()
    } catch (error) {
      setCreating(false)
      toast({
        title: "Error.",
        description: "Ha ocurrido un error guardando la cita.",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleEventChange = (eventData) => {
    if (!eventInfo) {
      try {
        const newAppointment = {
          timestampDate: dayjs(eventData.event.startStr).valueOf(),
          duration: dayjs(eventData.event.endStr).diff(
            eventData.event.startStr,
            "m"
          ),
        }
        const result = firebase.updateAppointment(
          newAppointment,
          provider.id,
          eventData.event.id
        )

        const second = firebase.updateLiveAppointment(
          newAppointment,
          provider.id,
          eventData.event.id
        )
        toast({
          title: "Cita actualizada.",
          description: "Se ha actualizado la cita.",
          status: "success",
          duration: 1500,
          isClosable: true,
        })
      } catch (error) {
        eventData.jsEvent.preventDefault()
        toast({
          title: "Error.",
          description: "Ha ocurrido un error actualizando la cita.",
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
    }
  }

  const handleDateSelect = (selectInfo) => {
    setInfo(selectInfo)
    onOpen()
  }

  const handleEventSelect = async (eventData) => {
    eventData.jsEvent.preventDefault()
    const snapshot = await firebase.getAppointmentById(
      provider.id,
      eventData.event.id
    )
    setEventInfo({ evtId: eventData.event.id, ...snapshot.data() })
    onOpenEdit()
  }

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      if (inputValue && inputValue.length >= 5) {
        index.search(inputValue).then(({ hits }) => {
          setClients(hits)
          const mappedHits = hits.map((h) => ({
            label: `${h.displayName} - ${h.phoneNumber} - ${h.legalId}`,
            value: h.objectID,
          }))
          resolve(mappedHits)
        })
      } else {
        resolve([])
      }
    })

  useEffect(() => {
    let unsubscribe = null
    if (provider) {
      const calendar = calendarRef.current.getApi()
      let currentEvents = localEvents
      unsubscribe = firebase.subscribeToAppointments({
        providerId: get(provider, "id"),
        onSnapshot: (s) => {
          // let currentEvents = localEvents
          // .filter(
          //   (l) => get(l, ["extendedProps", "isNewEvent"], false) === false
          // )
          // console.log("localEvents", localEvents)

          s.docChanges().forEach((change) => {
            if (change.type === "added") {
              const data = change.doc.data()
              // console.log("added", currentEvents, data.id)
              const exist = currentEvents.filter((e) => e.id === data.id)
              // console.log("exist", exist)
              if (exist.length === 0) {
                setStatus(true)
                const parsedData = {
                  id: data.id,
                  title: `${data.order > 1 ? `${data.order} - ` : ""} ${
                    data.client
                  } - ${data.reason} - ${data.clientPhoneNumber}`,
                  start: dayjs(data.timestampDate)
                    // .utc()
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
                  end: dayjs(data.timestampDate)
                    .add(data.duration, "m")
                    // .utc()
                    .format("YYYY-MM-DDTHH:mm:ssZ"),
                  backgroundColor: data.status.includes("A")
                    ? "green"
                    : data.status.includes("C")
                    ? "red"
                    : "#339FFF",
                  canceled: !!data.status.includes("C"),
                  extendedProps: {
                    isNewEvent: true,
                  },
                }
                const currentEvent = calendar.getEventById(parsedData.id)
                if (!currentEvent) {
                  calendar.addEvent(parsedData)
                }
                currentEvents.push(parsedData)
              }
            }
            if (change.type === "modified") {
              const data = change.doc.data()
              // console.log("currentEvents", currentEvents)
              // console.log("id", data.id)
              const newAppointments = currentEvents.filter(
                (a) => a.id !== data.id
              )
              const parsedData = {
                id: data.id,
                title: `${data.order > 1 ? `${data.order} - ` : ""} ${
                  data.client
                } - ${data.reason} - ${data.clientPhoneNumber}`,
                start: dayjs(data.timestampDate)
                  // .utc()
                  .format("YYYY-MM-DDTHH:mm:ssZ"),
                end: dayjs(data.timestampDate)
                  .add(data.duration, "m")
                  // .utc()
                  .format("YYYY-MM-DDTHH:mm:ssZ"),
                backgroundColor: data.status
                  ? data.status.includes("A")
                    ? "green"
                    : data.status.includes("C")
                    ? "red"
                    : "#339FFF"
                  : "green",
                canceled: !!data.status.includes("C"),
              }
              newAppointments.push(parsedData)
              // console.log("newAppointments", newAppointments)
              currentEvents = newAppointments
            }
            if (change.type === "removed") {
              const data = change.doc.data()
              const newAppointments = currentEvents.filter(
                (a) => a.id !== data.id
              )
              currentEvents = newAppointments
            }
          })
          setStatus(false)
          setLocalEvents(currentEvents)
        },
      })
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [localEvents])

  useEffect(() => {
    if (provider || !status) {
      const calendar = calendarRef.current.getApi()
      const eventsList = calendar.getEvents()
      eventsList
        .filter((e) => e.extendedProps.isNewEvent)
        .forEach((event) => {
          event.remove()
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider, status])

  useEffect(() => {
    // console.log("events", events)
    setLocalEvents(events)
  }, [events])

  const ExampleCustomInput = ({ value: valueT, onClick }) => (
    <Button
      type="button"
      border="1px"
      borderColor="inherit"
      p="5"
      borderRadius="4px"
      w="150px"
      background="white"
      onClick={onClick}
    >
      {valueT || "Seleccionar"}
    </Button>
  )

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        timeZone="America/Costa_Rica"
        height="calc(100vh - 100px)"
        locale="es"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        allDaySlot={false}
        slotLabelFormat={{
          hour: "numeric",
          minute: "2-digit",
          omitZeroMinute: false,
          meridiem: "short",
          hour12: true,
        }}
        slotDuration="00:15"
        slotMinTime="06:30"
        slotMaxTime="22:00"
        nowIndicator
        select={handleDateSelect}
        eventChange={handleEventChange}
        initialView="timeGridDay"
        editable
        selectable
        selectConstraint="businessHours"
        eventConstraint="businessHours"
        selectMirror
        dayMaxEvents
        businessHours={{
          daysOfWeek: [1, 2, 3, 4, 5, 6],
          startTime: "06:30",
          endTime: "22:00",
        }}
        unselectAuto={false}
        eventClick={handleEventSelect}
        events={
          showCanceled === "0"
            ? localEvents
            : localEvents.filter((e) => !e.canceled)
        }
        buttonText={{
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          list: "Lista",
        }}
      />
      <Box position="absolute" bottom="3" right="5">
        <RadioGroup onChange={setShowCanceled} value={showCanceled}>
          <Stack spacing={5} direction="row">
            <Radio colorScheme="teal" value="1">
              Ocultar canceladas
            </Radio>
            <Radio colorScheme="red" value="0">
              Mostrar canceladas
            </Radio>
          </Stack>
        </RadioGroup>
      </Box>
      <Drawer
        size="sm"
        isOpen={isOpenEdit}
        placement="left"
        onClick={onCloseEdit}
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Editar cita</DrawerHeader>
            <DrawerBody>
              <Text>{get(eventInfo, "reason")}</Text>
              <Text>{get(eventInfo, "client")}</Text>
              <Text>ID: {get(eventInfo, "clientLegalId", "N/A")}</Text>
              <Text>
                {`Tel: ${get(eventInfo, "clientPhoneNumber")} - ${get(
                  eventInfo,
                  "clientMobileNumber"
                )}`}
              </Text>
              <Text>Correo: {get(eventInfo, "clientEmail", "N/A")}</Text>
              <br />
              <Select
                placeholder="Seleccionar estado"
                className="basic-single"
                classNamePrefix="select"
                noOptionsMessage={() => "No hay estados"}
                defaultValue={
                  eventInfo &&
                  statusOptions.find((d) => d.value === eventInfo.status)
                }
                isSearchable
                name="duration"
                options={statusOptions}
                onChange={(value) =>
                  setEventInfo((currentEvent) => ({
                    ...currentEvent,
                    status: value.value,
                  }))
                }
              />
              <br />
              <Select
                placeholder="Buscar motivo"
                className="basic-single"
                classNamePrefix="select"
                noOptionsMessage={() => "No hay resultados"}
                defaultValue={
                  eventInfo &&
                  types
                    .map((t) => ({ value: t.id, label: t.description }))
                    .find((d) => d.value === eventInfo.reasonId)
                }
                isSearchable
                name="type"
                options={types.map((t) => ({
                  value: t.id,
                  label: t.description,
                }))}
                onChange={(value) =>
                  setEventInfo((currentEvent) => ({
                    ...currentEvent,
                    reason: value.label,
                    reasonId: value.value,
                  }))
                }
              />
              <br />
              <Select
                placeholder="Seleccionar duración"
                className="basic-single"
                classNamePrefix="select"
                noOptionsMessage={() => "No hay resultados"}
                defaultValue={
                  eventInfo &&
                  durationOptions.find((d) => d.value === eventInfo.duration)
                }
                isSearchable
                name="duration"
                options={durationOptions}
                onChange={(value) =>
                  setEventInfo((currentEvent) => ({
                    ...currentEvent,
                    duration: value.value,
                  }))
                }
              />
              <br />
              <Input
                value={get(eventInfo, "comments")}
                placeholder="Comentario..."
                onChange={(e) => {
                  setEventInfo((currentEvent) => ({
                    ...currentEvent,
                    comments: e.target.value,
                  }))
                }}
              />
              <br />
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={creating}
                onClick={handleEventUpdate}
              >
                Modificar
              </Button>
              <Spacer />
              <Button disabled mt={4} colorScheme="teal">
                Enviar Recordatorio
              </Button>
            </DrawerBody>
            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  setEventInfo(null)
                  onCloseEdit()
                }}
              >
                Cancelar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClick={handleDrawerClose}
        size="sm"
      >
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader>Crear una cita</DrawerHeader>
            <DrawerBody>
              <Tabs index={tabIndex} onChange={(i) => setTabIndex(i)} isLazy>
                <TabList>
                  <Tab>Cita</Tab>
                  <Tab>Clientes</Tab>
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Box>
                      <Select
                        placeholder="Buscar motivo"
                        className="basic-single"
                        classNamePrefix="select"
                        noOptionsMessage={() => "No hay resultados"}
                        defaultValue={types.length > 0 && types[0].id}
                        isSearchable
                        name="type"
                        options={types.map((t) => ({
                          value: t.id,
                          label: t.description,
                        }))}
                        onChange={(value) => setType(value)}
                      />
                      <br />
                      <AsyncSelect
                        isClearable
                        cacheOptions
                        defaultOptions
                        loadOptions={promiseOptions}
                        noOptionsMessage={() => "No hay resultados"}
                        loadingMessage={() => "Buscando..."}
                        placeholder="Buscar cliente"
                        onChange={(value) => setClient(value)}
                      />
                      <br />
                      <Select
                        placeholder="Seleccionar duración"
                        className="basic-single"
                        classNamePrefix="select"
                        noOptionsMessage={() => "No hay resultados"}
                        defaultValue={duration}
                        isSearchable
                        name="duration"
                        options={durationOptions}
                        onChange={(value) => setDuration(value)}
                      />
                      <br />
                      <Input
                        placeholder="Comentario..."
                        onChange={(e) => {
                          setNotes(e.target.value)
                        }}
                      />
                      <Button
                        mt={4}
                        colorScheme="teal"
                        isLoading={creating}
                        onClick={handleDrawerSave}
                        isDisabled={!type || !client || !duration}
                      >
                        Guardar
                      </Button>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box>
                      <Formik
                        initialValues={{
                          name: undefined,
                          lastName: undefined,
                          email: "",
                          legalId: undefined,
                          mobileNumber: "",
                          phoneNumber: undefined,
                          address: "",
                          legalType: "0",
                          gender: "M",
                          birthday: undefined,
                        }}
                        validationSchema={Yup.object({
                          legalId: Yup.string()
                            .trim()
                            .test(
                              "numbers-9-10-11",
                              "El formato no es válido",
                              (value, context) => {
                                const validate =
                                  context.parent.legalType === "0"
                                    ? value &&
                                      value.match(/^([1-9])\d{4}\d{4}$/) &&
                                      value.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                                    : value &&
                                      value.match(/^[0-9]{11}$|^[0-9]{12}$/) &&
                                      value.match(/^(?!^0+$)[a-zA-Z0-9]{3,20}$/)
                                return validate
                              }
                            )
                            .required("La identificacíón es requerida"),
                          name: Yup.string().required("Nombre es requerido"),
                          lastName: Yup.string().required(
                            "Al menos un apellido es requerido"
                          ),
                          phoneNumber: Yup.string()
                            .matches("^[0-9]{8}$", "El formato no es válido")
                            .required("Teléfono es requerido"),
                          mobileNumber: Yup.string().matches(
                            "^[0-9]{8}$",
                            "El formato no es válido"
                          ),
                          email: Yup.string().email(
                            "Debe cumplir con el formato."
                          ),
                          gender: Yup.string().required(
                            "El género es requerido"
                          ),
                        })}
                        onSubmit={async (values, actions) => {
                          try {
                            const cl = await firebase.addClients({
                              ...values,
                              birthday: values.birthday
                                ? dayjs(values.birthday).valueOf()
                                : null,
                              created: dayjs().valueOf(),
                            })
                            if (values.email) {
                              fetch(MAIL_URL, {
                                method: "POST",
                                body: JSON.stringify({
                                  data: {
                                    templateId: 2096181, // welcome template
                                    email: values.email,
                                  },
                                }),
                                headers: {
                                  Authorization: `Bearer ${cl.token}`,
                                  "Content-Type": "application/json",
                                },
                              })
                            }
                            actions.setSubmitting(false)
                            toast({
                              title: "Cliente agregado.",
                              description: `Se ha agregado el cliente correctamente!, ${values.legalId}`,
                              status: "success",
                              duration: 9000,
                              isClosable: false,
                            })
                            clipboard.copy()
                            setTimeout(() => {
                              setTabIndex(0)
                            }, 2000)
                          } catch (error) {
                            toast({
                              title: "Error.",
                              description:
                                "Ha ocurrido un error agregando el cliente.",
                              status: "error",
                              duration: 4000,
                              isClosable: true,
                            })
                          }
                        }}
                      >
                        {({ isSubmitting, dirty, isValid, setFieldValue }) => (
                          <Form>
                            <Field name="legalType">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.legalType &&
                                    form.touched.legalType
                                  }
                                >
                                  <FormLabel htmlFor="legalType">
                                    Tipo:
                                  </FormLabel>
                                  <RadioGroup
                                    {...field}
                                    onChange={(value) => {
                                      setFieldValue(
                                        "legalType",
                                        value.toString()
                                      )
                                    }}
                                    id="legalType"
                                  >
                                    <Stack direction="row">
                                      <Radio id="opt-1" value="0">
                                        Física
                                      </Radio>
                                      <Radio id="opt-2" value="1">
                                        DIMEX
                                      </Radio>
                                    </Stack>
                                  </RadioGroup>
                                  <FormErrorMessage>
                                    {form.errors.legalaType}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="legalId">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.legalId && form.touched.legalId
                                  }
                                >
                                  <FormLabel htmlFor="legalId">
                                    Cédula:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdRecentActors color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="legalId"
                                      placeholder="00000000"
                                      ref={clipboard.target}
                                    />
                                  </InputGroup>

                                  <FormErrorMessage>
                                    {form.errors.legalId}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="name">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.name && form.touched.name
                                  }
                                >
                                  <FormLabel htmlFor="name">Nombre:</FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdPerson color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="name"
                                      placeholder="Nombre"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.name}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="lastName">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.lastName &&
                                    form.touched.lastName
                                  }
                                >
                                  <FormLabel htmlFor="lastName">
                                    Apellidos:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdPersonOutline color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="lastName"
                                      placeholder="Apellidos"
                                    />
                                  </InputGroup>

                                  <FormErrorMessage>
                                    {form.errors.lastName}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="phoneNumber">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.phoneNumber &&
                                    form.touched.phoneNumber
                                  }
                                >
                                  <FormLabel htmlFor="phoneNumber">
                                    Teléfono 1:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdPhone color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="phoneNumber"
                                      type="phone"
                                      placeholder="22222222"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.phoneNumber}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="mobileNumber">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.mobileNumber &&
                                    form.touched.mobileNumber
                                  }
                                >
                                  <FormLabel htmlFor="mobileNumber">
                                    Teléfono 2:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdPhoneIphone color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="mobileNumber"
                                      type="phone"
                                      placeholder="88888888"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.mobileNumber}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="email">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.email && form.touched.email
                                  }
                                >
                                  <FormLabel htmlFor="email">
                                    Correo Electrónico:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdMail color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      type="email"
                                      id="email"
                                      placeholder="correo@test.com"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.email}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="address">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.address && form.touched.address
                                  }
                                >
                                  <FormLabel htmlFor="address">
                                    Dirección:
                                  </FormLabel>
                                  <InputGroup>
                                    <InputLeftElement
                                      pointerEvents="none"
                                      fontSize="1.2em"
                                      p="0"
                                      zIndex="1"
                                    >
                                      <MdLocationOn color="gray.300" />
                                    </InputLeftElement>
                                    <Input
                                      {...field}
                                      id="address"
                                      placeholder="Dirección"
                                    />
                                  </InputGroup>
                                  <FormErrorMessage>
                                    {form.errors.address}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="gender">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.gender && form.touched.gender
                                  }
                                >
                                  <FormLabel htmlFor="gender">
                                    * Género:
                                  </FormLabel>
                                  <RadioGroup
                                    {...field}
                                    onChange={(valuel) => {
                                      setFieldValue("gender", valuel)
                                    }}
                                    id="gender"
                                  >
                                    <Stack direction="row">
                                      <Radio id="opt-gen-1" value="M">
                                        Masculino
                                      </Radio>
                                      <Radio id="opt-gen-2" value="F">
                                        Femenino
                                      </Radio>
                                    </Stack>
                                  </RadioGroup>
                                  <FormErrorMessage>
                                    {form.errors.gender}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Field name="birthday">
                              {({ field, form }) => (
                                <FormControl
                                  isInvalid={
                                    form.errors.birthday &&
                                    form.touched.birthday
                                  }
                                >
                                  <FormLabel htmlFor="birthday">
                                    Fecha Nacimiento:
                                  </FormLabel>
                                  <DatePicker
                                    isClearable
                                    dateFormat="dd/MM/yyyy"
                                    renderCustomHeader={({
                                      date,
                                      changeYear,
                                      changeMonth,
                                      decreaseMonth,
                                      increaseMonth,
                                      prevMonthButtonDisabled,
                                      nextMonthButtonDisabled,
                                    }) => (
                                      <div
                                        style={{
                                          margin: 10,
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <button
                                          type="button"
                                          onClick={decreaseMonth}
                                          disabled={prevMonthButtonDisabled}
                                        >
                                          {"<"}
                                        </button>
                                        <select
                                          value={dayjs(date).format("YYYY")}
                                          onChange={({
                                            target: { value: valueY },
                                          }) => {
                                            changeYear(valueY)
                                          }}
                                        >
                                          {years.map((option) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                        <select
                                          value={
                                            months[dayjs(date).format("MMMM")]
                                          }
                                          onChange={({
                                            target: { value: valueM },
                                          }) => {
                                            changeMonth(months.indexOf(valueM))
                                          }}
                                        >
                                          {months.map((option) => (
                                            <option key={option} value={option}>
                                              {option}
                                            </option>
                                          ))}
                                        </select>
                                        <button
                                          type="button"
                                          onClick={increaseMonth}
                                          disabled={nextMonthButtonDisabled}
                                        >
                                          {">"}
                                        </button>
                                      </div>
                                    )}
                                    customInput={<ExampleCustomInput />}
                                    selected={field.value}
                                    onChange={(date) => {
                                      setFieldValue("birthday", date)
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {form.errors.birthday}
                                  </FormErrorMessage>
                                </FormControl>
                              )}
                            </Field>
                            <Button
                              mt={4}
                              colorScheme="teal"
                              isLoading={isSubmitting}
                              type="submit"
                              disabled={!dirty || isSubmitting || !isValid}
                            >
                              Crear
                            </Button>
                          </Form>
                        )}
                      </Formik>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={handleDrawerClose}>
                Cancelar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

Calendar.defaultProps = {
  provider: null,
  events: [],
}

Calendar.propTypes = {
  types: PropTypes.any.isRequired,
  events: PropTypes.any,
  provider: PropTypes.string,
  firebase: PropTypes.shape().isRequired,
  handleDateChange: PropTypes.func.isRequired,
}

export default withFirebase(Calendar)
