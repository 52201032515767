import React from "react"
import PropTypes from "prop-types"
import AuthUserContext from "./context"
import { withFirebase } from "../../utils/Firebase"

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        authUser: {
          ...JSON.parse(localStorage.getItem("authUser")),
          loading: true,
        },
      }
    }

    componentDidMount() {
      const { firebase } = this.props
      this.listener = firebase.onAuthUserListener(
        (authUser) => {
          localStorage.setItem(
            "authUser",
            JSON.stringify({ ...authUser, loading: false })
          )
          this.setState({ authUser: { ...authUser, loading: false } })
        },
        () => {
          localStorage.removeItem("authUser")
          this.setState({ authUser: { loading: false } })
        }
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    render() {
      const { authUser } = this.state
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  WithAuthentication.propTypes = {
    firebase: PropTypes.shape().isRequired,
  }
  return withFirebase(WithAuthentication)
}

export default withAuthentication
