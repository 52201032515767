/* eslint-disable react/no-children-prop */
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Flex,
  Box,
  Button,
  Stack,
  RadioGroup,
  Radio,
  Text,
  Icon,
  Checkbox,
  Divider,
  Input,
  InputRightElement,
  InputGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
} from "@chakra-ui/react"
import { SearchIcon } from "@chakra-ui/icons"
import { MdFilterList } from "react-icons/md"
import { withFirebase } from "../../../utils/Firebase"

const MarketingTargets = ({ firebase, listId, actualList }) => {
  const [type, setType] = useState("both")
  const [filter, setFilter] = useState("0")
  const [types, setTypes] = useState([])
  const [checkedItems, setCheckedItems] = useState({})
  const [value, setValue] = useState("")
  const [loading, setLoading] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [gender, setGender] = useState("A")
  const [age, setAge] = useState(18)
  const [clients, setClients] = useState([])
  const [filteredClients, setFilteredClients] = useState([])
  // const [actualList, setActualList] = useState([])
  const [selectedClients, setSelectedClients] = useState({})
  const handleAgeChange = (val) => setAge(val)
  const handleChange = (event) => setValue(event.target.value)
  const toast = useToast()

  useEffect(() => {
    const getTypes = async () => {
      const snapshot = await firebase.getTypes()
      const data = snapshot.docs
        .map((doc) => ({ id: doc.id, ...doc.data() }))
        .sort((a, b) => (a.description > b.description ? 1 : -1))
      setTypes(data)
      // const cls = await firebase.getContactsMarketingList(listId)
      // const newCls = cls.docs.map((doc) => doc.data())
      // setActualList(newCls)
    }
    if (firebase) {
      getTypes()
    }
  }, [firebase])

  useEffect(() => {
    if (type === "email") {
      if (gender === "A") {
        setFilteredClients(clients.filter((c) => c.email))
      } else {
        setFilteredClients(
          clients.filter((c) => c.email && c.gender === gender)
        )
      }
    } else if (type === "phone") {
      if (gender === "A") {
        setFilteredClients(
          clients.filter(
            (c) =>
              (c.phoneNumber || c.mobileNumber) &&
              ((c.phoneNumber && c.phoneNumber[0] !== "2") ||
                (c.mobileNumber && c.mobileNumber[0] !== "2"))
          )
        )
      } else {
        setFilteredClients(
          clients.filter(
            (c) =>
              c.gender === gender &&
              (c.phoneNumber || c.mobileNumber) &&
              ((c.phoneNumber && c.phoneNumber[0] !== "2") ||
                (c.mobileNumber && c.mobileNumber[0] !== "2"))
          )
        )
      }
    } else if (gender === "A") {
      setFilteredClients(
        clients.filter(
          (c) =>
            c.email ||
            ((c.phoneNumber || c.mobileNumber) &&
              ((c.phoneNumber && c.phoneNumber[0] !== "2") ||
                (c.mobileNumber && c.mobileNumber[0] !== "2")))
        )
      )
    } else {
      setFilteredClients(
        clients.filter(
          (c) =>
            c.gender === gender &&
            (c.email ||
              ((c.phoneNumber || c.mobileNumber) &&
                ((c.phoneNumber && c.phoneNumber[0] !== "2") ||
                  (c.mobileNumber && c.mobileNumber[0] !== "2"))))
        )
      )
    }
  }, [type, clients, gender])

  useEffect(() => {
    if (filter !== "0") {
      setCheckedItems({})
    }
  }, [filter])

  const getTotalClients = () => {
    const keys = Object.keys(selectedClients)
    if (keys.length > 0) {
      const activeKeys = keys.filter((k) => selectedClients[k])
      return activeKeys.length
    }
    return 0
  }

  return (
    <Flex h="calc(100vh - 200px)" justify="flex-start">
      <Box w="275px" p="2" borderRight="1px solid #f2f2f2">
        <Text fontSize="xl" align="left" mb="5">
          <SearchIcon boxSize={4} color="teal.500" /> Tipo Contacto:
        </Text>
        <RadioGroup onChange={setType} value={type}>
          <Stack spacing={5} direction="column">
            <Radio colorScheme="teal" value="both">
              Ambos
            </Radio>
            <Radio colorScheme="teal" value="email">
              Correo
            </Radio>
            <Radio colorScheme="teal" value="phone">
              Teléfono
            </Radio>
          </Stack>
        </RadioGroup>
        <Divider my="5" />
        <Text fontSize="xl" align="left">
          <Icon as={MdFilterList} boxSize={4} color="teal.500" /> Buscar Por:
        </Text>
        <RadioGroup onChange={setFilter} value={filter} mb="5">
          <Stack spacing={5} direction="column">
            <Radio colorScheme="teal" value="0">
              Motivos
            </Radio>
            <Radio colorScheme="teal" value="1">
              Edad
            </Radio>
          </Stack>
        </RadioGroup>
        <Divider my="5" />
        {filter === "0" && (
          <Box>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                variant="filled"
                placeholder="Filtrar motivos"
                value={value}
                onChange={handleChange}
                colorScheme="teal"
                mb="2"
              />
              <InputRightElement width="6rem">
                <Button h="1.75rem" size="sm" onClick={() => setValue("")}>
                  Limpiar
                </Button>
              </InputRightElement>
            </InputGroup>
            <Flex justify="flex-start" height="30vh" overflowY="auto">
              <Stack spacing={5} direction="column">
                {types
                  .filter((t) =>
                    value !== ""
                      ? t.description
                          .toLowerCase()
                          .includes(value.toLowerCase())
                      : true
                  )
                  .map((t) => (
                    <Checkbox
                      pl="1"
                      m="0"
                      align="left"
                      size="sm"
                      key={t.id}
                      isChecked={checkedItems[t.id]}
                      onChange={(e) => {
                        setCheckedItems((prevValues) => ({
                          ...prevValues,
                          [t.id]: e.target.checked,
                        }))
                      }}
                    >
                      {t.description}
                    </Checkbox>
                  ))}
              </Stack>
            </Flex>
          </Box>
        )}
        {filter === "1" && (
          <Flex>
            <NumberInput
              maxW="100px"
              mr="2rem"
              value={age}
              onChange={handleAgeChange}
              min={15}
              max={100}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            <Slider
              flex="1"
              focusThumbOnChange={false}
              value={age}
              onChange={handleAgeChange}
              min={15}
              max={100}
            >
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb fontSize="sm" boxSize="32px" children={age} />
            </Slider>
          </Flex>
        )}
        <Button
          mt={4}
          colorScheme="teal"
          type="button"
          isLoading={loading}
          onClick={async () => {
            const keys = Object.keys(checkedItems)
            if (keys.length > 0) {
              const activeKeys = keys.filter((k) => checkedItems[k])
              if (activeKeys.length > 0) {
                setLoading(true)
                try {
                  const data = await firebase.searchClientsByAppointmentsType(
                    activeKeys
                  )
                  const keysActualClients = actualList.map((ac) => ac.id)
                  let localFilteredClients = []
                  if (type === "email") {
                    localFilteredClients = data.filter(
                      (cl) => !keysActualClients.includes(cl.id) && cl.email
                    )
                  } else if (type === "phone") {
                    localFilteredClients = data.filter(
                      (cl) =>
                        !keysActualClients.includes(cl.id) &&
                        (cl.phoneNumber || cl.mobileNumber)
                    )
                  } else {
                    localFilteredClients = data.filter(
                      (cl) =>
                        !keysActualClients.includes(cl.id) &&
                        (cl.email ||
                          ((cl.phoneNumber || cl.mobileNumber) &&
                            ((cl.phoneNumber && cl.phoneNumber[0] !== "2") ||
                              (cl.mobileNumber && cl.mobileNumber[0] !== "2"))))
                    )
                  }

                  setClients(localFilteredClients)
                  const newObject = {}
                  localFilteredClients.forEach((cc) => {
                    newObject[cc.id] = false
                  })
                  setSelectedClients(newObject)
                  setLoading(false)
                } catch {
                  setLoading(false)
                }
              }
            }
          }}
        >
          Buscar
        </Button>
      </Box>
      <Box flex="1" height="calc(100vh - 200px)" position="relative" py="40px">
        <Checkbox
          position="absolute"
          left="2"
          top="2"
          size="sm"
          isChecked={checkAll}
          onChange={(e) => {
            const newObject = {}
            filteredClients.forEach((cc) => {
              newObject[cc.id] = e.target.checked
            })
            setCheckAll(e.target.checked)
            setSelectedClients(newObject)
          }}
        >
          {checkAll
            ? `Desmarcar Todos (${getTotalClients()})`
            : `Seleccionar Todos (${filteredClients.length})`}
        </Checkbox>
        {filteredClients.length > 0 && (
          <Box position="absolute" left="190" top="2">
            <RadioGroup onChange={setGender} value={gender} size="sm">
              <Stack spacing={2} direction="row">
                <Radio colorScheme="teal" value="A">
                  Ambos
                </Radio>
                <Radio colorScheme="teal" value="M">
                  Masculino
                </Radio>
                <Radio colorScheme="teal" value="F">
                  Femenino
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        )}
        <Flex
          w="100%"
          h="100%"
          minHeight="500px"
          justify="center"
          flexWrap="wrap"
          overflowY="auto"
        >
          {filteredClients.map((ct) => (
            <Box
              position="relative"
              m={4}
              p={2}
              minWidth="300px"
              maxWidth="500px"
              borderWidth={1}
              borderRadius={8}
              boxShadow="sm"
              key={ct.id}
              flex="1"
              height="140px"
            >
              <Text align="left" size="sm">
                {ct.id}
              </Text>
              <Text align="left">{ct.name}</Text>
              {ct.email && (
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Email: {ct.email}
                </Box>
              )}
              {ct.phoneNumber && (
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Teléfono 1: {ct.phoneNumber}
                </Box>
              )}
              {ct.mobileNumber && (
                <Box textAlign="left" as="p" color="gray.600" fontSize="sm">
                  Teléfono 2: {ct.mobileNumber}
                </Box>
              )}
              <Checkbox
                position="absolute"
                top="3"
                right="3"
                size="sm"
                key={ct.id}
                isChecked={
                  selectedClients[ct.id] || (checkAll && selectedClients[ct.id])
                }
                onChange={(e) => {
                  setSelectedClients((prevValues) => ({
                    ...prevValues,
                    [ct.id]: e.target.checked,
                  }))
                }}
              >
                {selectedClients[ct.id] ? "Quitar" : "Seleccionar"}
              </Checkbox>
            </Box>
          ))}
        </Flex>
        <Button
          position="fixed"
          right="2"
          bottom="2"
          mt={4}
          colorScheme="teal"
          type="button"
          isLoading={loading}
          onClick={async () => {
            setLoading(true)
            try {
              const keys = Object.keys(selectedClients)
              if (keys.length > 0) {
                const activeKeys = keys.filter((k) => selectedClients[k])
                const localClients = filteredClients.filter((cc) =>
                  activeKeys.includes(cc.id)
                )
                await firebase.addClientsToMarketingList(listId, localClients)
                const addedClients = filteredClients.filter(
                  (cc) => !activeKeys.includes(cc.id)
                )
                setClients(addedClients)
                setFilteredClients(addedClients)
                toast({
                  title: "Clientes agregados.",
                  description: "Se han agregado a la lista exitósamente",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                })
              }
              setLoading(false)
            } catch (error) {
              toast({
                title: "Error.",
                description: "Ha ocurrido un error, intenta de nuevo.",
                status: "error",
                duration: 2000,
                isClosable: true,
              })
              setLoading(false)
            }
          }}
        >
          <Text>{`Agregar a Lista (${getTotalClients()})`}</Text>
        </Button>
      </Box>
    </Flex>
  )
}

MarketingTargets.propTypes = {
  firebase: PropTypes.shape().isRequired,
  listId: PropTypes.string.isRequired,
  actualList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default withFirebase(MarketingTargets)
