import { theme as chakraTheme } from "@chakra-ui/react"

const theme = {
  ...chakraTheme,
  fonts: {
    ...chakraTheme.fonts,
    body: "Circular Std Book",
    heading: "Circular Std Bold",
  },
  fontWeights: {
    normal: 400,
    medium: 600,
    bold: 800,
  },
}
export default theme
